<template>
  <div class="col-md-12 col-lg-10 center">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol>
            <CAlert :color="alertColor" closeButton :show.sync="alertShow">
              {{ alertMessage }}
            </CAlert>
          </CCol>
        </CRow>
        <CRow class="align-items-center">
          <CCol> Create new tutoring session </CCol>
          <CCol md="auto">
            <div class="text-right">
              <CButton color="primary" @click="saveItem">
                Create Session
              </CButton>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CSelect label="Grade" horizontal :value.sync="session.grade" :options="gradeOptions"
          placeholder="Select grade" />
        <CSelect label="Subject" horizontal :value.sync="session.subject" :options="subjectOptions"
          placeholder="Select subject" />
        <CSelect label="Chapter" horizontal :value.sync="session.chapter" :options="chapterOptions"
          placeholder="Select chapter" />
        <CSelect label="Language" horizontal :value.sync="session.language" :options="languageOptions"
          placeholder="Select language" />
        <CSelect label="Model" horizontal :value.sync="session.model" :options="modelOptions"
          placeholder="Select model" />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios";
import { AUTH_LOGOUT } from "../store/actions/auth";

export default {
  name: "NewItem",
  data() {
    return {
      alertColor: "success",
      alertShow: false,
      alertMessage: "",
      session: {
        grade: "",
        subject: "Math",
        chapter: "",
        language: "English",
        model: "gpt-4",
      },
      gradeOptions: [
        "1st",
        "2nd",
        "3rd",
        "4th",
        "5th",
        "6th",
      ],
      subjectOptions: ["Math"],
      languageOptions: ["English", "Indonesia"],
      modelOptions: ["gpt-3.5", "gpt-4"],
      chapterOptions: []
    };
  },
  watch: {
    'session.grade': function (newGrade, oldGrade) {
      this.updateChapters();
    },
    'session.subject': function (newSubject, oldSubject) {
      this.updateChapters();
    }
  },
  mounted() {
    let selectElements = this.$el.querySelectorAll('select');

    selectElements.forEach(select => {
      let initialOption = select.options[select.selectedIndex];

      if (initialOption && initialOption.text === select.getAttribute('placeholder')) {
        select.classList.add('select-placeholder');
      }

      select.addEventListener('change', function () {
        if (this.options[this.selectedIndex].text === this.getAttribute('placeholder')) {
          this.classList.add('select-placeholder');
        } else {
          this.classList.remove('select-placeholder');
          this.classList.add('select-selected');
        }
      });
    });
  },
  methods: {
    updateChapters() {
      if (this.session.grade && this.session.subject) {
        // get chapters from API
        axios.get(config.VUE_APP_ENV_TUTOR_URL + "/chapters", {
          params: {
            grade: this.session.grade,
            subject: this.session.subject
          }
        }).then((resp) => {
          this.chapterOptions = resp.data.chapters;
        }).catch((err) => {
          if (err.message.includes("401")) {
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
              this.$router.push("/login");
            });
          } else {
            this.showAlert("warning", "Failed to get chapters: " + err);
          }
        });
      } else {
        this.chapterOptions = [];
      }
    },
    saveItem() {
      axios
        .post(config.VUE_APP_ENV_TUTOR_URL + "/sessions", this.session)
        .then((resp) => {
          this.showAlert("success", "New session is created");
          this.$router.push("/sessions/" + resp.data.session.tutor_session_id);
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
              this.$router.push("/login");
            });
          } else {
            this.showAlert("warning", "Failed to create session: " + err);
          }
        });
    },
    showAlert(color, messsage) {
      this.alertShow = true;
      this.alertColor = color;
      this.alertMessage = messsage;
    },
    notEmpty(val) {
      return val && val.length >= 3;
    },
  },
};
</script>

<style scoped>
.center {
  margin: 0 auto;
}

/* Define color for the selected option */
.select-selected {
  color: black !important;
}

/* Define color for the placeholder text */
.select-placeholder {
  color: gray !important;
}
</style>
